<template>
  <section id="home-partners" class="team">
    <div class="container">
      <div class="sec-title" data-aos="fade-up">
        <h2>TITANS LEAGUE PARTNERS</h2>
        <p>MORE REWARDS AND MORE ENTERTAINMENT</p>
      </div>

      <div class="row partners" data-aos="fade-left">
        <div class="col-md-2">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <a
                href="https://www.twitch.tv/titansleagueofficial"
                target="_blank"
              >
                <img
                  src="@/assets/img/partners/twitch.png"
                  class="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic dark">
              <a href="https://lanergy.eu" target="_blank">
                <img
                  src="@/assets/img/partners/lanergy.png"
                  class="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="member" data-aos="zoom-in" data-aos-delay="100">
            <div class="pic">
              <a href="https://dynastyesports.nl" target="_blank">
                <img
                  src="@/assets/img/partners/dynasty.png"
                  class="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-white">PARTNER UP</button>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionPartners",
};
</script>

<style scoped lang="scss">
#home-partners {
  background-color: black;
  text-align: center;
}

.sec-title {
  h2 {
    color: white;
    font-weight: bold;
  }

  p {
    color: white;
  }
}

.partners {
  margin: 60px 0 0;
  justify-content: space-between;
}

.member {
  border-radius: 50%;
  overflow: hidden;
  background-color: white;

  .pic.dark {
    background-color: darkgray;
  }
}

button {
  padding: 8px 80px;
}
</style>
