<template>
  <section id="home-calendar" class="section">
    <div class="background-overlay"></div>
    <div class="container">
      <VueperSlides
        class="no-shadow"
        :visible-slides="5"
        :parallax="false"
        :init-slide="3"
        :gap="2"
        :dragging-distance="35"
        fixed-height="550px"
        :breakpoints="{
          1200: { visibleSlides: 3 },
          900: { visibleSlides: 1 },
        }"
      >
        <VueperSlide class="slider" v-for="slide in slides" :key="slide.id">
          <template v-slot:content>
            <div class="slide" :style="getStyle(slide)">
              <div class="content">
                <h4>{{ slide.title }}</h4>
                <span class="date" v-if="slide.date != null">
                  {{ getDate(slide) }}<br />
                  {{ slide.spotsLeft }} SPOTS LEFT
                </span>
                <p>{{ slide.description }}</p>
                <button class="btn btn-white" v-if="slide.date != null">
                  RESERVE SPOT
                </button>
              </div>
            </div>
          </template>
        </VueperSlide>
      </VueperSlides>
    </div>
  </section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "SectionHomeEvents",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: [
        {
          id: 1,
          title: "COMING SOON",
          date: null,
          spotsLeft: 50,
          backgroundImg: null,
          description: "",
        },

        {
          id: 2,
          title: "COMING SOON",
          date: null,
          spotsLeft: 50,
          backgroundImg: null,
          description: "",
        },

        {
          id: 3,
          title: "WANBA WARRIORS",
          date: new Date("2022-07-16"),
          spotsLeft: 0,
          backgroundImg:
            "https://cdn.akamai.steamstatic.com/steam/apps/1021770/capsule_616x353.jpg?t=1613135088",
          description:
            "The Titans League organises the fourth Wanba Warriors Tournament. The tournament will be held on the 16th and 17th of July 2022. This first day will be a group stage, the second double elimination bracket. Sign up is open for everyone!",
        },

        {
          id: 4,
          title: "COMING SOON",
          date: null,
          spotsLeft: 50,
          backgroundImg: null,
          description: "",
        },

        {
          id: 5,
          title: "COMING SOON",
          date: null,
          spotsLeft: 50,
          backgroundImg: null,
          description: "",
        },
      ],
    };
  },
  methods: {
    getDate(event) {
      if (event.data == null) {
        return "Date unknown";
      }
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Augustus",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[event.date.getMonth()] + " " + event.date.getDate();
    },
    getStyle(slide) {
      return slide.backgroundImg != null
        ? "background: url('" +
            slide.backgroundImg +
            "'), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));"
        : "";
    },
  },
};
</script>

<style scoped lang="scss">
#home-calendar {
  position: relative;
  background-image: url("../../assets/img/backgrounds/home-events.jpg");
  background-size: cover;

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 0;
  }

  .container {
    z-index: 1;
  }

  .slider {
    .slide {
      color: white;
      padding: 15px;
      background: url("../../assets/img/backgrounds/coming-soon.png"),
        linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-blend-mode: overlay;
      height: 550px;

      .content {
        height: 100%;
        transform: rotateY(180deg);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transform-style: preserve-3d;
        transition: transform 0.8s;
        opacity: 0;
      }

      &:hover .content {
        opacity: 1;
        transform: rotateY(0deg);
      }

      h4 {
        z-index: 1;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
      }

      span {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        display: block;
        width: 100%;
      }

      p {
        margin: 10px 0;
        font-size: 12px;
        padding: 0 15px;
      }
    }
  }

  .vueperslide {
    transition: 0.3s ease-in-out;
    opacity: 0.8;
    transform: scale(0.9);

    &.vueperslide--active {
      opacity: 1 !important;
      transform: scale(1);
    }
  }
}
</style>
