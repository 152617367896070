<template>
  <MyHeader :color="$route.name === 'Home' ? 'white' : 'black'" />
  <router-view />
  <MyFooter />
</template>

<script>
import MyHeader from "@/components/Template/MyHeader";
import MyFooter from "@/components/Template/MyFooter";

export default {
  components: {
    MyHeader,
    MyFooter,
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
