<template>
  <section id="home-videos" class="section">
    <div class="container">
      <div class="sec-title" data-aos="fade-up">
        <h2>WATCH THE BEST</h2>
        <p>MORE REWARDS AND MORE ENTERTAINMENT</p>
      </div>
      <div class="row">
        <a
          :href="video.url"
          target="_blank"
          class="col-md-4"
          v-for="video in videos"
          :key="video.id"
        >
          <div class="video">
            <div class="video-inner">
              <img
                class="preview"
                :src="video.previewImg"
                alt="Preview image"
              />
              <div class="overlay">
                <img src="@/assets/img/video-overlay.png" alt="Video overlay" />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionVideos",
  data() {
    return {
      videos: [
        {
          id: 1,
          previewImg: process.env.BASE_URL + "assets/img/gallery/video1.jpeg",
          url: "https://www.twitch.tv/titansleagueofficial/clip/KawaiiFrigidRavenArsonNoSexy",
        },
        {
          id: 2,
          previewImg: process.env.BASE_URL + "assets/img/gallery/video2.jpeg",
          url: "https://www.twitch.tv/titansleagueofficial/clip/TriangularVivaciousCamelCmonBruh",
        },
        {
          id: 3,
          previewImg: process.env.BASE_URL + "assets/img/gallery/video3.jpeg",
          url: "https://www.twitch.tv/titansleagueofficial/clip/ResilientSuccessfulShingleTheTarFu",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#home-videos {
  background-color: white;
  text-align: center;
}

.sec-title {
  h2 {
    color: black;
    font-weight: bold;
  }

  p {
    color: black;
  }
}

.video {
  padding: 0 10px;
  box-sizing: border-box;

  .video-inner {
    background-color: black;
    position: relative;

    img.preview {
      width: 100%;
      height: 100%;
    }

    .overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      img {
        width: 100px;
        height: 100px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 768px) {
  .video {
    margin-bottom: 20px;
  }
}
</style>
