import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/ViewHome.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ViewLogin.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ViewRegister.vue"),
  },
  {
    path: "/wanba-warriors",
    name: "WanbaWarriors",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ViewWanbaWarriors.vue"),
  },
  {
    path: "/info",
    name: "Info",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ViewInfo.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
