<template>
  <section id="home-calendar" class="section">
    <div class="background-overlay"></div>
    <div class="container">
      <div class="calendar">
        <div v-for="month in months" :key="month.id">
          <div class="header">
            <h4>{{ month.month }} {{ month.year }}</h4>
            <span class="line"></span>
          </div>
          <div class="event" v-for="event in month.events" :key="event.id">
            <div class="weekday">{{ getWeekdayDay(event) }}</div>
            <div class="info">
              <h5>{{ event.title }}</h5>
              <h6>{{ getTiming(event) }}</h6>
              <p>{{ event.descriptionShort }}</p>
              <router-link to="/register" class="btn btn-black">
                Reserve spot
              </router-link>
            </div>
            <img :src="event.img" alt="Event image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
#home-calendar {
  position: relative;
  background-image: url("../../assets/img/backgrounds/home-calendar.jpg");
  background-size: cover;

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 0;
  }

  .container {
    z-index: 1;
    display: flex;
    justify-content: center;
  }
}

.calendar {
  max-width: 700px;
  z-index: 10;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    text-transform: uppercase;
    font-size: 16px;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }

  .line {
    flex-grow: 100;
    background-color: lightgrey;
    margin-left: 10px;
    height: 3px;
  }
}

.event {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 150px;
  margin: 5px 0;

  .weekday {
    background-color: black;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 14px;
    padding: 2px 5px;
  }

  .info {
    flex-grow: 100;
    padding: 5px 15px;
    background-color: darkgoldenrod;
    color: black;

    h5 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
    }

    h6 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }

    p {
      font-size: 12px;
    }
  }

  img {
    width: 150px;
  }
}
</style>

<script>
export default {
  name: "SectionHomeCalendar",
  data() {
    return {
      months: [
        {
          id: 1,
          month: "July",
          year: "2022",
          events: [
            {
              id: 1,
              date: new Date("2022-07-16 19:00:00"),
              title: "Wanba warriors - 32 Players",
              starts: new Date("2022-07-16 19:00:00"),
              ends: new Date("2022-07-17 19:00:00"),
              descriptionShort:
                "Sign up for the Wanba Warriors Tournament here!",
              img: "/assets/img/events/tournament-16-17-july.jpeg",
            },
          ],
        },
      ],
    };
  },
  methods: {
    getWeekdayDay(event) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[event.date.getDay()] + " " + event.date.getDate();
    },
    getTiming(event) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Augustus",
        "September",
        "October",
        "November",
        "December",
      ];
      return (
        months[event.starts.getMonth()] +
        " " +
        event.starts.getDate() +
        " - " +
        months[event.ends.getMonth()] +
        " " +
        event.ends.getDate()
      );
    },
  },
};
</script>
