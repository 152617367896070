<template>
  <!-- ======= Header ======= -->
  <header
    id="header"
    :class="{
      'header-scrolled': headerScrolled,
      'color-black': color === 'black',
      'fixed-top d-flex align-items-center header-transparent': true,
    }"
  >
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/img/logo-white.png" alt="" class="img-fluid" />
        </router-link>
      </div>

      <nav id="navbar" class="navbar">
        <ul style="display: block">
          <!--          <li>-->
          <!--            <router-link class="nav-link" to="/login">Login</router-link>-->
          <!--          </li>-->
          <li>
            <router-link class="nav-link" to="/register"
              >Sign Up For Tournament</router-link
            >
          </li>
          <!--          <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>-->
          <!--            <ul>-->
          <!--              <li><a href="#">Drop Down 1</a></li>-->
          <!--              <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>-->
          <!--                <ul>-->
          <!--                  <li><a href="#">Deep Drop Down 1</a></li>-->
          <!--                  <li><a href="#">Deep Drop Down 2</a></li>-->
          <!--                  <li><a href="#">Deep Drop Down 3</a></li>-->
          <!--                  <li><a href="#">Deep Drop Down 4</a></li>-->
          <!--                  <li><a href="#">Deep Drop Down 5</a></li>-->
          <!--                </ul>-->
          <!--              </li>-->
          <!--              <li><a href="#">Drop Down 2</a></li>-->
          <!--              <li><a href="#">Drop Down 3</a></li>-->
          <!--              <li><a href="#">Drop Down 4</a></li>-->
          <!--            </ul>-->
          <!--          </li>-->
        </ul>
        <i class="bi bi-list mobile-nav-toggle" style="display: none"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>
export default {
  name: "MyHeader",
  data() {
    return {
      headerScrolled: false,
    };
  },
  props: {
    color: String,
  },
  created() {
    window.onscroll = () => {
      this.headerScrolled = window.scrollY > 0;
    };
  },
};
</script>

<style scoped lang="scss">
.navbar {
  a {
    color: white;

    &:before {
      background-color: white;
    }
  }
}

.color-black {
  background-color: black;
  color: white;
}

#header.header-scrolled {
  background-color: black;
}
</style>
