<template>
  <!-- ======= Header ======= -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>TitansLeague</span></strong
        >. All Rights Reserved - Created by
        <a href="https://www.linkedin.com/in/thomas-oomens" target="_blank"
          >Thomas Oomens</a
        >
      </div>
    </div>

    <div class="social-links mt-3">
      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
    </div>
  </footer>
  <!-- End Footer -->
  <!-- End Header -->
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style scoped lang="scss">
#footer {
  background-color: black;
  color: white;
  position: relative;
}

a {
  color: white;
}

.social-links {
  position: absolute;
  right: 25px;
  bottom: 25px;

  a {
    font-size: 20px;
    margin: 0 10px;
  }
}

@media (max-width: 768px) {
  #footer {
    padding-bottom: 70px;

    .social-links {
      width: calc(100% - 50px);
      text-align: center;
    }
  }
}
</style>
