<template>
  <!-- ======= Counts Section ======= -->
  <section id="counts" class="counts">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-lg-3 col-md-6">
          <div class="count-box">
            <i class="bi bi-emoji-smile"></i>
            <span
              data-purecounter-start="0"
              data-purecounter-end="232"
              data-purecounter-duration="1"
              class="purecounter"
              >232</span
            >
            <p>Amused viewers</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
          <div class="count-box">
            <i class="bi bi-journal-richtext"></i>
            <span
              data-purecounter-start="0"
              data-purecounter-end="2"
              data-purecounter-duration="1"
              class="purecounter"
              >2</span
            >
            <p>Tournaments</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="count-box">
            <i class="bi bi-headset"></i>
            <span
              data-purecounter-start="0"
              data-purecounter-end="265"
              data-purecounter-duration="1"
              class="purecounter"
              >265</span
            >
            <p>Hours of gameplay</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div class="count-box">
            <i class="bi bi-people"></i>
            <span
              data-purecounter-start="0"
              data-purecounter-end="151"
              data-purecounter-duration="1"
              class="purecounter"
              >151</span
            >
            <p>Participants</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Counts Section -->
</template>

<script>
export default {
  name: "SectionCounters",
};
</script>

<style scoped lang="scss">
.counts {
  background-color: black;

  .count-box i {
    background-color: #00171d;
  }
}
</style>
